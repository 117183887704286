<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockReport" />
      <v-form ref="form">
        <v-container fluid>
          <!--検索項目-->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                :hint="setSrhSupplier()"
                persistent-hint
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="targetDateFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_targetDate') + '（From）'"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="targetDateFromCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="preDateOfTargetDateFrom" text>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateOfTargetDateFrom" text>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- from toの～ -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="targetDateTo"
                class="txt-single"
                :label="$t('label.lbl_targetDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="targetDateToCal" @input="dateMenu2 = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="preDateOfTargetDateTo" text>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateOfTargetDateTo" text>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ここから追加 -->
            <!--  v-row 1 対象品番 始まり -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                id="get-reservation"
                v-model="targetProductDivSelected"
                :items="targetProductDivList"
                :label="$t('label.lbl_targetProductDiv')"
                class="txt-single"
                :hint="setTargetProductDiv()"
                persistent-hint
                :rules="[rules.targetProductDivRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- v-row 1 対象品番 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- v-row 1 出力形式 始まり -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                id="get-reservation"
                v-model="outputFormatDivSelected"
                :items="outputFormatDivList"
                :label="$t('label.lbl_outputFormatDiv')"
                class="txt-single"
                :hint="setOutputFormatDiv()"
                persistent-hint
                :rules="[rules.outputFormatDivRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- v-row 1 出力形式 終わり -->

            <!-- ここまで追加 -->
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--在庫報告書発行ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 在庫報告書発行ボタン-->
                <v-btn color="primary" class="api-btn" @click="getStockReport()">
                  {{ $t("btn.btn_stockReport") }}
                </v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
            </div>
          </v-row>
          <!-- ２行目ここまで -->

          <!-- 空欄領域 -->
          <v-row>
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <span class="item-spacer">&nbsp;</span>
          </v-row>
        </v-container>
      </v-form>

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :screen="infoDialog.changeFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import SimpleDialog from "@/components/SimpleDialog";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.SCREEN_ID.P_SRG_003,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    loadingCounter: 0,
    // 承認・未承認
    isConfirmed: "2",
    //検索ボタンをクリックした時に挙動を変える
    isActive: 1,
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      changeFlag: false,
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 承認ボタン活性化
    ableConfirm: true,
    // データ作成ボタン活性化
    confirmDone: true,
    // ダウンロードボタン活性化
    createDone: true,
    dialog: false,
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 対象期間From
    targetDateFrom: "",
    targetDateFromCal: "",
    // 対象期間To
    targetDateTo: "",
    targetDateToCal: "",
    // カレンダー
    dateMenu: false,
    dateMenu2: false,
    returnURL: "",
    // 対象品番区分
    targetProductDivSelected: "",
    // 出力形式区分
    outputFormatDivSelected: "",
    // バリデーション
    alertMessage: "",
    // ヘッダ
    supplierList: [],
    targetProductDivList: [],
    outputFormatDivList: [],
    // 初期データ
    defaultData: [],
    // バリデーション
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      targetProductDivRequired: (value) => !!value || i18n.tc("check.chk_targetProductDiv"),
      outputFormatDivRequired: (value) => !!value || i18n.tc("check.chk_outputFormatDiv"),
    },
  }),
  methods: {
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      // 初期表示はconvertJST
      this.targetDateFrom = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.targetDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.targetDateTo = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.targetDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getInitData();
    },
    // 初期データ取得
    getInitData() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 対象品番区分
      const targetProductDiv = getParameter.getCodeMst(appConfig.CODETYPE.TARGETPRODUCT_DIV);
      // 出力形式区分
      const outputFormatDiv = getParameter.getCodeMst(appConfig.CODETYPE.OUTPUTFORMAT_DIV);
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      Promise.all([targetProductDiv, outputFormatDiv, clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.targetProductDivList = result[0];
          this.outputFormatDivList = result[1];
          this.supplierList = result[2];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 対象期間日付カレンダーFrom
    preDateOfTargetDateFrom() {
      if (this.targetDateFromCal == null) {
        return;
      }
      let date = new Date(this.targetDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.targetDateFromCal != toDate) {
        this.targetDateFromCal = toDate;
      } else {
        this.targetDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDateOfTargetDateFrom() {
      if (this.targetDateFromCal == null) {
        return;
      }
      let date = new Date(this.targetDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.targetDateFromCal != toDate) {
        this.targetDateFromCal = toDate;
      } else {
        this.targetDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    // 対象期間日付カレンダーto
    preDateOfTargetDateTo() {
      if (this.targetDateToCal == null) {
        return;
      }
      let date = new Date(this.targetDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.targetDateToCal != toDate) {
        this.targetDateToCal = toDate;
      } else {
        this.targetDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDateOfTargetDateTo() {
      if (this.targetDateToCal == null) {
        return;
      }
      let date = new Date(this.targetDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.targetDateToCal != toDate) {
        this.targetDateToCal = toDate;
      } else {
        this.targetDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    // カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.targetDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.targetDateFromCal = null;
      }
    },
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.targetDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.targetDateToCal = null;
      }
    },

    /**
     * URL発行
     */
    link() {
      location.href = this.returnURL;
    },

    /**
     * 取引先コードリストボックスセット処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 対象品番区分リストセット処理
     */
    setTargetProductDiv() {
      for (var i = 0; i < this.targetProductDivList.length; i++) {
        if (this.targetProductDivList[i].value == this.targetProductDivSelected) {
          return this.targetProductDivList[i].name;
        }
      }
    },

    /**
     * 出力形式区分リストセット処理
     */
    setOutputFormatDiv() {
      for (var i = 0; i < this.outputFormatDivList.length; i++) {
        if (this.outputFormatDivList[i].value == this.outputFormatDivSelected) {
          return this.outputFormatDivList[i].name;
        }
      }
    },

    /**
     * 在庫報告書発行API
     */
    getStockReport() {
      // Fromの日付がtoよりも先だった場合、エラー
      if (new Date(this.targetDateFrom).getTime() > new Date(this.targetDateTo).getTime()) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        // バリデーションエラーメッセージを非表示
        if (this.$refs.form.validate()) {
          this.alertMessage = "";

          // ローディング画面表示ON
          this.loadingCounter = 1;
          const body = this.$httpClient.createGetApiRequestConfig();

          // 共通IF項目 画面ID
          body.params.reqComComponentId = appConfig.SCREEN_ID.P_SRG_003;

          // 営業所SID
          body.params.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");

          // 取引先SID
          body.params.clientSid = this.suppliersSelected;

          // 日時From
          body.params.targetDateFrom = dateTimeHelper.convertUTC(this.targetDateFrom);

          // 日時To
          var changedDate = this.targetDateTo + " 23:59:59.999";
          body.params.targetDateTo = dateTimeHelper.convertUTC(changedDate);

          // 対象品番
          body.params.targetItemDiv = this.targetProductDivSelected;

          // 出力形式区分
          body.params.outputFormatDiv = this.outputFormatDivSelected;

          // console.debug("getStockReport() Body", body);
          return new Promise((resolve, reject) => {
            this.$httpClient
              .secureGet(appConfig.API_URL.BIZ_STOCKREPORT, body, appConfig.APP_CONFIG)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // console.debug("getStockReport() Response", response);

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                  this.returnURL = jsonData.resIdv.filePath;
                  this.link();
                  resolve(response);

                  // エラー時
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  reject();
                }
              })

              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              })

              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 変更検知用の設定
      this.targetDateFromCal = "";
      this.targetDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    recieveDateCal(val) {
      this.shippingScheduleDate = this.formatDate(val);
    },
    targetDateFromCal: {
      handler(val) {
        this.targetDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    targetDateToCal: {
      handler(val) {
        this.targetDateTo = this.formatDate(val);
      },
      deep: true,
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.toatlNum {
  margin-left: 10px;
}

.text-overflow {
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
